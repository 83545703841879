import { ReactNode, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useI18n, Text } from '@northladder/i18n';
import { getTextFromI18n, usePAAS } from '@northladder/utilities';
import { ActionBarButton, CircularLoader } from '@northladder/design-system';

import {
  AppPageHeader,
  TAppNavMenuItem,
  IAppNavBarOptions,
  useSessionInstance,
} from '../../features';
import { translations } from '../../translations';

import { DashboardLayoutContainer, PageTitleWrapper } from '../../styles';
import { BackgroundDecor } from './BackgroundDecor';
import { SessionModal } from './SessionModal';

/**
 * List of items to be displayed within the AppNav Menu.
 * TODO: Get these items from the API request
 */
export const appNavMenuItemsPaasConfig: TAppNavMenuItem[] = [
  {
    name: translations.dealsWon,
    path: '/my-deals',
    icon: 'fire',
  },
  {
    name: translations.uploadBids,
    path: '/upload-bids',
    icon: 'fileUpload',
  },
  {
    name: translations.winningBids,
    path: '/bids',
    icon: 'badge',
  },
];

/**
 * List of items to be displayed within the AppNav Menu.
 * TODO: Get these items from the API request
 */
export const appNavMenuItemsConfig: TAppNavMenuItem[] = [
  {
    name: translations.dashboard,
    path: '/dashboard',
    icon: 'house',
  },
  {
    name: translations.bids,
    path: '/bids',
    icon: 'chart',
  },
  {
    name: translations.reports,
    path: '/reports',
    icon: 'chart',
    isExpandable: true,
  },
  {
    name: translations.support,
    path: '/support',
    icon: 'help',
  },
  {
    name: translations.settings,
    path: '/profile/settings',
    icon: 'settings',
  },
];

interface IDashboardLayoutProps extends IAppNavBarOptions {
  /**
   * The list of menu items to be displayed on flyout App Nav menu.
   */
  appNavMenuItems?: TAppNavMenuItem[];
  /**
   * The title to be displayed in the browser tab at the top. Falls back to
   * using the `pageTitle` as the `browserTitle`.
   * Note: Add context browser title improves user experience for multi-tabs.
   */
  browserTitle?: string;
  /**
   * The content to be displayed in the main body of the dashboard.
   */
  children: ReactNode;
  /**
   * The class(s) to be passed down to the container element of the dashboard.
   */
  containerClassName?: string;
  /**
   * By default the `browserTitle` will be appended to `Dealer @ NorthLadder`,
   * but this forces to overwrite it completely.
   */
  replaceBrowserTitle?: boolean;
  /**
   * Show back navigation icon or not. Currently supported for PAAS only.
   */
  showBackNavigation?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a reusable layout for views that look like dashboards and exposes
 * some configurable properties to allow for the consumer pages to customize it.
 * Note that this will later be migrated to the app directory as a layout.
 */
export function DashboardLayout({
  appNavMenuItems,
  browserTitle,
  children,
  containerClassName,
  pageTitle,
  replaceBrowserTitle = false,
  showOverflowActions,
  showProfile,
  showSearch,
  showBackNavigation,
}: IDashboardLayoutProps) {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const { locale = 'en' } = useI18n();
  const { activeSession } = useSessionInstance();
  const { isPaasEnabled } = usePAAS();
  const router = useRouter();

  useEffect(() => {
    if (!activeSession) {
      router.replace('/login');
    }

    setIsLoadingPage(() => false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = replaceBrowserTitle ? browserTitle : browserTitle || pageTitle;
  const textTitle = getTextFromI18n({ text: title || '', locale });

  const navigationItems = useMemo(() => {
    if (isPaasEnabled) {
      return appNavMenuItemsPaasConfig;
    }
    return appNavMenuItems || appNavMenuItemsConfig;
  }, [isPaasEnabled, appNavMenuItems]);

  const handleBackNavigation = () => router.back();

  if (isLoadingPage) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-purple-100">
        <div className="flex flex-col items-center justify-center gap-8">
          <CircularLoader className="h-16 w-16" />
          <p className="text-xl text-purple-600">Please wait...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>{textTitle}</title>
      </Head>

      <BackgroundDecor />

      <DashboardLayoutContainer className={containerClassName}>
        <AppPageHeader
          appNavMenuItems={navigationItems}
          pageTitle={pageTitle}
          showOverflowActions={showOverflowActions}
          showProfile={showProfile}
          showSearch={showSearch}
        />
        {isPaasEnabled ? (
          <PageTitleWrapper
            className="
              flex-row items-stretch !justify-start text-2xl font-medium
            "
          >
            {showBackNavigation ? (
              <ActionBarButton
                className="no-border-button"
                icon="iconArrowDown"
                iconClassName="h-5 w-5 rotate-90"
                label=""
                onClick={handleBackNavigation}
              />
            ) : null}

            <Text text={pageTitle} />
          </PageTitleWrapper>
        ) : null}
        {children}
      </DashboardLayoutContainer>
      {activeSession?.isExpired ? <SessionModal isDismissible /> : null}
    </>
  );
}
