export const translations = {
  profile: {
    en: 'Profile',
    ar: 'الملف الشخصي',
  },
  dealerNorthLadder: {
    en: 'Dealer @ NorthLadder',
    ar: 'NorthLadder @ تاجر',
  },
  dashboard: {
    en: 'Dashboard',
    ar: 'لوحة القيادة',
  },
  bids: {
    en: 'Bids',
    ar: 'المناقصة',
  },
  winningBids: {
    en: 'Winning bids',
    ar: 'العطاءات الفائزة',
  },
  dealsWon: {
    en: 'Deals won',
    ar: 'فازت الصفقات',
  },
  dealDetails: {
    en: 'Deal details',
    ar: 'تفاصيل الصفقة',
  },
  uploadBids: {
    en: 'Upload bids',
    ar: 'تحميل العطاءات',
  },
  reports: {
    en: 'Reports',
    ar: 'التقارير',
  },
  team: {
    en: 'Team',
    ar: 'فريق',
  },
  settings: {
    en: 'Settings',
    ar: 'إعدادات',
  },
  support: {
    en: 'Support',
    ar: 'الدعم',
  },
  changePassword: {
    en: 'Change Password',
    ar: 'تغيير كلمة المرور',
  },
  formSubmission: {
    pleaseWait: {
      en: 'Please Wait',
      ar: 'ارجوك انتظر',
    },
  },
  fallbackPages: {
    notFound: {
      title: {
        en: 'Sorry about that!',
        ar: 'اسف بشأن ذلك!',
      },
      message: {
        en: "We can't find the page you're looking for. If you're having trouble finding what you need, contact our support team, and we will be happy to assist you.",
        ar: 'لا يمكننا العثور على الصفحة التي تبحث عنها. إذا كنت تواجه مشكلة في العثور على ما تحتاجه ، فاتصل بفريق الدعم الخاص بنا ، وسنكون سعداء بمساعدتك.',
      },
      action: {
        en: 'Go Home',
        ar: 'اذهب للمنزل',
      },
      altActionText: {
        en: 'Need Support?',
        ar: 'بحاجة إلى دعم؟',
      },
      altActionLinkText: {
        en: 'Contact Us',
        ar: 'اتصل بنا',
      },
    },
    offline: {
      title: {
        en: 'Sorry, you are offline!',
        ar: 'آسف ، أنت غير متصل!',
      },
      message: {
        en: 'We are unable to complete your request. Please check your internet connection and try again.',
        ar: 'نحن غير قادرين على استكمال طلبك. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.',
      },
      action: {
        en: 'Go Home',
        ar: 'اذهب للمنزل',
      },
      altActionText: {
        en: 'About Us?',
        ar: 'معلومات عنا؟',
      },
      altActionLinkText: {
        en: 'Visit NorthLadder',
        ar: 'قم بزيارة NorthLadder',
      },
    },
  },
};
