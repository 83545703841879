import tw, { styled } from 'twin.macro';

export const LayoutPageWrapper = styled.main`
  ${tw`
    relative
    min-h-screen
  `};
`;

export const LayoutPaasBackground = styled.main`
  ${tw`
    absolute
    top-0
    left-0
    right-0
    bottom-0
    -z-50
    min-h-full
    min-w-full
    bg-opacity-5
    bg-gradient-to-r
    from-purple-50
    via-purple-200
    to-purple-50
  `};

  section {
    ${tw`
      bg-transparent
      dark:bg-gray-900
    `};
  }
`;

export const LayoutBackground = styled.main`
  ${tw`
    absolute
    top-0
    left-0
    right-0
    bottom-0
    -z-50
    min-h-full
    min-w-full
    bg-opacity-5
    bg-gradient-to-r
    from-purple-50
    via-purple-200
    to-purple-50
  `};

  section {
    ${tw`
      bg-transparent
      dark:bg-gray-900
    `};
  }
`;

export const LayoutContainer = styled.main`
  ${tw`
    flex
    h-full
    min-h-full
    w-full
    flex-col
    justify-center
    md:px-12
    lg:px-0
  `};

  section {
    ${tw`
      bg-transparent
      dark:bg-gray-900
    `};
  }
`;

export const FormMainContainer = styled.main`
  ${tw`
    flex
    flex-1
    flex-col
    bg-transparent
    -mt-10
    px-4
    py-10
    transition-all
    duration-500

    min-h-[calc(100vh-5rem)]

    sm:(-mt-20 justify-center px-0)
    md:(flex-none px-28)
  `};

  section {
    ${tw`
      bg-transparent
      dark:bg-gray-900
    `};
  }
`;

export const FormWrapper = styled.div`
  ${tw`
     mx-auto
     my-auto
     flex
     flex-col
     items-center
     justify-center
     py-0
     px-0
     transition-all
     duration-500
     lg:py-5
  `};
`;

export const FormWrapperSimple = styled.div`
  ${tw`
     flex
     flex-col
     transition-all
     duration-500
     w-full
  `};
`;
