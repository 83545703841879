import Link from 'next/link';

import {
  CompanyLogo,
  LogoNorthLadder,
  NavLinkCTAItem,
  NavLinkItem,
} from '@northladder/design-system';
import { useRegistrationOnly, usePAAS } from '@northladder/utilities';
import { LocaleSwitcher } from '@northladder/i18n';

interface ILoginLayoutHeaderProps {
  hasRegisterCTA?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the header for the login form and can be used across different
 * from facing forms.
 */
export function LoginLayoutHeader({
  hasRegisterCTA = true,
}: ILoginLayoutHeaderProps) {
  const { isPaasEnabled } = usePAAS();
  const isRegistrationOnly = useRegistrationOnly();

  // This is for the Northladder Logo in the header and where it navigates to.
  const headerLink: string = isRegistrationOnly ? 'registration' : '';

  return (
    <header className="relative py-2 transition-all duration-500 sm:py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav className="relative mt-4 flex justify-between py-4 sm:py-0">
          <div className="flex items-center md:gap-x-12">
            {isPaasEnabled ? (
              <CompanyLogo className="h-12" />
            ) : (
              <Link
                aria-label="Registration"
                className="focus:ring-offset-4 focus:ring-offset-violet-100"
                href={`/${headerLink}`}
              >
                <LogoNorthLadder className=" block h-4 sm:h-6" />
                {/* <LogoDealerNorthLadder className="h-6 block" />
                <LogoDealerNorthLadderShort className="hidden h-6 Xsm:hidden" /> */}
              </Link>
            )}
            <div className="m:flex hidden md:gap-x-6">
              <NavLinkItem
                altTitle="Get to know more about the features"
                label="Features"
                to="/app-features"
              />
              <NavLinkItem
                altTitle="Frequently Asked Questions"
                label="FAQs"
                to="/faqs"
              />
            </div>
          </div>

          <div className="flex items-center gap-x-2 md:gap-x-4">
            {!isPaasEnabled ? (
              <NavLinkCTAItem
                altTitle="Register today to become a dealer"
                className="hidden sm:block"
                isEnabled={hasRegisterCTA}
                label="Become a Dealer"
                labelExtra="today"
                to="/registration"
              />
            ) : null}

            {!isPaasEnabled ? (
              <LocaleSwitcher
                isInline
                localCTAClassName="hover:bg-purple-200 focus:ring-purple-200 focus:hover:bg-purple-200"
              />
            ) : null}

            <div className="-mr-1 hidden md:hidden">
              <div>
                <button
                  aria-expanded="false"
                  aria-label="Toggle Navigation"
                  className="
                    relative z-10 flex h-8 w-8 items-center justify-center
                    [&:not(:focus-visible)]:focus:outline-none
                  "
                  type="button"
                >
                  <svg
                    aria-hidden="true"
                    className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
                    fill="none"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <path
                      className="origin-center transition"
                      d="M0 1H14M0 7H14M0 13H14"
                    />
                    <path
                      className="origin-center scale-90 opacity-0 transition"
                      d="M2 2L12 12M12 2L2 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
