import { Fragment, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';

import {
  generateProfileImageURL,
  ILoginUserData,
  useLoginMutation,
} from '@northladder/apis';
import { usePAAS } from '@northladder/utilities';
import { TLocale, useAuthStore, useRegionStore } from '@northladder/services';
import { Text } from '@northladder/i18n';
import {
  CircularLoader,
  PasswordInputField,
  useForm,
} from '@northladder/design-system';

import { IconBtnContainer } from './styles';

interface ISessionFormInputFields {
  password: string;
}

/**
 * This interface defines the props for session modal component.
 */
interface ISessionModalProps {
  /**
   * This determines whether to show the button or not.
   */
  isDismissible?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This shows a modal on top of everything when the session expires.
 *
 * TODO: Extract out the separate logic into smaller self contained components.
 * TODO: Invalidate all relevant queries on session login success.
 */
export function SessionModal({ isDismissible = false }: ISessionModalProps) {
  const router = useRouter();
  const { isPaasEnabled } = usePAAS();
  const { activeSession, activityLog, addNewSession, removeSession } =
    useAuthStore();
  const { region: activeRegion } = useRegionStore();
  const { firstName, email, displayPicture } = activeSession?.user || {};
  const closeBtnClass = isDismissible ? 'hidden' : '';

  const [open, setOpen] = useState(true);

  const handleOnLoginError = () => {
    setOpen(true);
  };

  /**
   * Redirect the user to the dashboard or previous route attempt.
   * Persist the login Details to query cache.
   */
  const handleOnLoginSuccess = (loginUserData: ILoginUserData) => {
    const { user, token, refreshToken, identifier } = loginUserData;

    const newSessionIndex = addNewSession({
      user,
      locale: router.locale as TLocale,
      token,
      refreshToken,
      rememberMe: true,
      regionId: activeSession?.regionId || activeRegion?.id || 'uae',
      regionIconId: activeSession?.regionIconId || activeRegion?.icon || 'uae',
      identifier,
    });
    if (isPaasEnabled) {
      router.replace(`/${newSessionIndex}/my-deals`);
    } else {
      router.replace(`/${newSessionIndex}/dashboard`);
    }
  };

  const {
    error,
    isLoading: isFormSubmitting,
    submitLogin,
  } = useLoginMutation({
    onSuccessCallback: handleOnLoginSuccess,
    onErrorCallback: handleOnLoginError,
  });

  const handlePostDetails = (values: ISessionFormInputFields) => {
    submitLogin({
      deviceInfo: activityLog?.deviceInfo || {},
      email: email || '',
      password: values.password,
    });
  };

  /**
   * The fields to be tracked within the form.
   */
  const sessionInputFieldsValues: ISessionFormInputFields = {
    password: '',
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: sessionInputFieldsValues,
    onSubmitCb: handlePostDetails,
  });

  const handleDisableClose = () => null;

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavToLogin = () => {
    router.replace('/login');
  };

  if (!activeSession?.isExpired) {
    return null;
  }

  /**
   * TODO: For now we are redirecting to the login page but it should session
   * modal. Please update it later.
   */
  if (activeSession?.isExpired) {
    removeSession(activeSession);
    handleNavToLogin();
  }

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog as="div" className="relative z-50" onClose={handleDisableClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-50"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            aria-hidden="true"
            className="fixed inset-0 bg-zinc-900/95 transition-opacity"
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="
                  relative transform overflow-hidden rounded-lg bg-white px-4
                  pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full
                  sm:max-w-sm sm:p-6
                "
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <IconBtnContainer
                    className={`
                      rounded-md bg-white text-gray-400 hover:text-gray-500
                      focus:outline-none focus:ring-2 focus:ring-purple-500
                      focus:ring-offset-2
                      ${closeBtnClass}
                    `}
                    onClick={handleClose}
                    title="Dismiss this popup. I will sign in later."
                    type="button"
                  >
                    <span className="sr-only">
                      Close Popup. I will sign in later.
                    </span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </IconBtnContainer>
                </div>
                {isFormSubmitting ? (
                  <div>
                    <div className="relative mx-auto h-16 w-16 items-center justify-center rounded-full bg-red-100">
                      <Image
                        alt={`Profile picture for ${firstName}`}
                        className="absolute inline-block h-16 w-16 shrink-0 rounded-full"
                        height={64}
                        src={generateProfileImageURL(displayPicture || '')}
                        width={64}
                      />
                      <CircularLoader className="h-16 w-16" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-light leading-6 text-red-900"
                      >
                        Submitting...
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Verifying your credentials.
                        </p>
                        <p className="pt-2 text-sm font-light text-gray-500">
                          Please wait.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="relative mx-auto h-16 w-16 items-center justify-center rounded-full bg-red-100">
                      <Image
                        alt={`Profile picture for ${firstName}`}
                        className="absolute inline-block h-16 w-16 shrink-0 rounded-full"
                        height={64}
                        // placeholder="blur"
                        src={generateProfileImageURL(displayPicture || '')}
                        width={64}
                      />
                      <ClockIcon
                        aria-hidden="true"
                        className="absolute -bottom-1 right-0 h-8 w-8 rounded-full bg-red-100/90 p-1 text-red-600"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <div className="mb-2 w-full border-b border-gray-100 pb-2">
                        <p className="text-sm">{email || ''}</p>
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-light leading-6 text-red-900"
                      >
                        Session Expired
                      </Dialog.Title>
                      <div className="mt-2">
                        <Text
                          className="text-sm text-gray-900"
                          text={
                            error?.message ||
                            'Sorry, looks like your session has expired.'
                          }
                        />
                        <Text
                          className="pt-2 text-sm font-light text-gray-500"
                          text={
                            error?.description ||
                            'Please try to login in again to continue.'
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <form className="mt-5 sm:mt-6" onSubmit={handleOnSubmitForm}>
                  <PasswordInputField
                    editable={!isFormSubmitting}
                    error={errors.password}
                    label="Password"
                    name="password"
                    onBlurCallback={handleOnBlur}
                    onChangeCallback={handleOnInputChange}
                    onError={handleOnError}
                    value={values.password}
                  />
                  <button
                    className="form-btn mt-2"
                    disabled={isFormSubmitting}
                    type="submit"
                  >
                    Sign In
                  </button>
                </form>

                <div className="mt-2 w-full border-t border-gray-100 pt-2">
                  <button
                    className="form-btn-secondary"
                    disabled={isFormSubmitting}
                    onClick={handleNavToLogin}
                    type="button"
                  >
                    Use another Account
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
