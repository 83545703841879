/**
 * -----------------------------------------------------------------------------
 * This is used to render different color split in the background of the page
 * for large screens. Currently rendering different shades of gray but this can
 * be customizable for more illustrative layouts.
 */
export function BackgroundDecor() {
  return (
    <>
      <div
        aria-hidden="true"
        className="fixed left-0 top-0 h-full w-1/2 bg-[#fefefe]"
      />
      <div
        aria-hidden="true"
        className="fixed right-0 top-0 h-full w-1/2 bg-purple-50/20"
      />
    </>
  );
}
