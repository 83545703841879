import { ReactNode } from 'react';
import Head from 'next/head';

import { PaasFooter, PublicFooter } from '@northladder/design-system';
import { usePAAS } from '@northladder/utilities';

import { LoginLayoutHeader } from './LoginLayoutHeader';

import {
  LayoutBackground,
  LayoutPaasBackground,
  LayoutContainer,
  LayoutPageWrapper,
  FormMainContainer,
  FormWrapper,
} from './styles';

interface ILoginLayoutProps {
  /**
   * The title of the page to be shown in the browser.
   */
  title?: string;
  /**
   * The content to be shown in the body of the form, such as a login inputs.
   */
  children: ReactNode;
}

const PAGE_TITLE = 'Dealer @ NorthLadder';

/**
 * -----------------------------------------------------------------------------
 * This provides the base re-usable styles for the login page.
 */
export function LoginLayout({
  title = PAGE_TITLE,
  children,
}: ILoginLayoutProps) {
  const { isPaasEnabled } = usePAAS();

  return (
    <LayoutPageWrapper>
      <Head>
        <title>{title}</title>
      </Head>
      {isPaasEnabled ? <LayoutPaasBackground /> : <LayoutBackground />}
      <LayoutContainer>
        <LoginLayoutHeader />

        <FormMainContainer>
          <section>
            <FormWrapper>{children}</FormWrapper>
          </section>
        </FormMainContainer>

        {isPaasEnabled ? <PaasFooter /> : <PublicFooter />}
      </LayoutContainer>
    </LayoutPageWrapper>
  );
}
